<template>
  <!-- 修改资料 -->
  <div class="reset-pwd">
    <h3>修改资料</h3>
    <div class="info">
      <el-form ref="form" :model="form" label-width="150px" :rules="formRule">
        <el-form-item
          :label="identityObj.identityTypeCode == 2 ? '姓名：' : '名称：'"
        >
          <span>{{ identityObj.identityName }}</span>
        </el-form-item>
        <el-form-item
          :label="
            identityObj.identityTypeCode == 2 ? '身份证：' : '营业执照编号：'
          "
        >
          <span>{{ identityObj.identityCode }}</span>
        </el-form-item>
        <el-form-item label="地址：" prop="areaCode">
          <!-- <el-input
            style="width: 280px; margin-right: 10px"
            v-model="identityObj.areaFullName"
          ></el-input> -->
          <el-cascader
            ref="address"
            style="width: 380px"
            v-model="identityObj.areaCode"
            :props="props"
            :options="regionOptions"
            :show-all-levels="true"
            @change="regionChange"
            clearable
          ></el-cascader>
          <!-- <span>{{ identityObj.areaFullName }}</span> -->
        </el-form-item>
        <el-form-item label="手机：" prop="landArea">
          <span style="display: inline-block; width: 210px">{{
            identityObj.userName
          }}</span>
          <el-button class="btn" type="info" @click="showChangeTel = true"
            >变更</el-button
          >
        </el-form-item>
        <el-form-item label="银行卡号：" prop="landArea">
          <el-input
            style="width: 280px; margin-right: 10px"
            v-model="identityObj.bankCardId"
          ></el-input>
          <!-- <span>{{
            identityObj.bankCardId ? identityObj.bankCardId : "-"
          }}</span> -->
        </el-form-item>
        <el-form-item
          label="联系人："
          prop="landArea"
          v-if="identityObj.identityTypeCode != 2"
        >
          <span style="display: inline-block; width: 210px">{{
            identityObj.contactName
          }}</span
          ><el-button class="btn" type="info" @click="changePeopleDialog"
            >变更联系人信息</el-button
          >
        </el-form-item>
        <el-form-item label="联系人电话：" prop="landArea">
          <span>{{ identityObj.contactPhone }}</span>
        </el-form-item>
      </el-form>
      <el-button type="success" @click="submit">确认修改</el-button>
    </div>
    <el-dialog title="修改手机" :visible.sync="showChangeTel" width="510px">
      <el-form :model="form2" label-width="120px" :rules="ruleTel" ref="form2">
        <el-form-item label="新手机号码" prop="tel">
          <el-input
            v-model="form2.tel"
            style="width: 200px; margin-right: 12px"
          ></el-input>
          <el-button
            class="btn"
            type="info"
            @click="getTelCode"
            :disabled="flag"
            >{{ flag ? `（${current}） s` : "发送验证码" }}</el-button
          >
        </el-form-item>
        <el-form-item label="验证码" prop="yzm">
          <el-input v-model="form2.yzm" style="width: 200px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -12px">
        <el-button @click="showChangeTel = false">取 消</el-button>
        <el-button type="primary" @click="changeTel">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="修改联系人及联系人电话"
      :visible.sync="showChangePeople"
      width="510px"
    >
      <el-form :model="form3" label-width="120px" :rules="rules3" ref="form3">
        <el-form-item label="联系人" prop="username">
          <el-input
            v-model="form3.username"
            style="width: 200px; margin-right: 12px"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人电话" prop="tel">
          <el-input
            v-model="form3.tel"
            style="width: 200px; margin-right: 12px"
          ></el-input>
          <el-button
            class="btn"
            type="info"
            @click="getUserCode"
            :disabled="flag2"
            >{{ flag2 ? `（${current2}） s` : "发送验证码" }}</el-button
          >
        </el-form-item>
        <el-form-item label="验证码" prop="yzm">
          <el-input v-model="form3.yzm" style="width: 200px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -12px">
        <el-button @click="showChangePeople = false">取 消</el-button>
        <el-button type="primary" @click="changeUser">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

      <script>
import { getSmsCodeNew } from "@/api/certificate";
import {
  changeLoginPhone,
  changeContract,
  getXzqArr,
  changeUserInfo,
  getUserNow,
  getIdentityList,
} from "@/api/add";
import { mapGetters } from "vuex";
import { removeEmptyChildren } from "@/js/utils/util";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value.length < 6 || value.length > 32) {
        callback(new Error("密码长度在6~32个字符之间"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else if (value.length < 6 || value.length > 32) {
        callback(new Error("密码长度在6~32个字符之间"));
      } else {
        callback();
      }
    };
    return {
      zlform: {},
      keyForm: 0,
      regionOptions: [{}],
      props: {
        label: "areaName",
        children: "children",
        value: "id",
        checkStrictly: true,
      },
      form2: {},
      form3: {},
      ruleTel: {
        tel: [{ required: true, message: "请输入新手机号码", trigger: "blur" }],
        yzm: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      rules3: {
        username: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        tel: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
        yzm: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      flag: false,
      flag2: false,
      max: 60,
      current: 60,
      current2: 60,
      showChangePeople: false,
      showChangeTel: false,
      flag1: false,
      form: {},
      formRule: {
        oldPwd: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
        newPwd: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            pattern: /^[a-zA-Z][a-zA-Z0-9]{7,17}$/,
            message: "以字母开头，长度在8~18之间，只能包含字母和数字",
            trigger: "blur",
          },
          { validator: validatePass, trigger: "blur" },
        ],
        newPwd2: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          {
            pattern: /^[a-zA-Z][a-zA-Z0-9]{7,17}$/,
            message: "以字母开头，长度在8~18之间，只能包含字母和数字",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  mounted() {
    this.getRegions();
  },
  methods: {
    regionChange() {
      let node = this.$refs.address.getCheckedNodes()[0];
      this.zlform.areaCode = node.value;
    },
    async getRegions() {
      // 获取地区信息
      let res = await getXzqArr({
        level: 5,
      });
      if (res.code == 0) {
        let hasRegion = setInterval(() => {
          this.regionOptions = removeEmptyChildren(res.data);
          this.keyForm++;
          clearInterval(hasRegion);
        }, 100);
      }
    },
    changePeopleDialog() {
      this.showChangePeople = true;
    },
    changeTel() {
      this.$refs.form2.validate(async (valid) => {
        if (valid) {
          let params = {
            username: this.identityObj.userName,
            mobile: this.form2.tel,
            smsCode: this.form2.yzm,
          };
          let res = await changeLoginPhone(params);
          if (res.code == 0) {
            this.$message.success(res.msg);
            this.showChangeTel = false;
            this.identityObj.contactPhone = this.form2.tel;
            this.form2={};
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    changeUser() {
      this.$refs.form3.validate(async (valid) => {
        if (valid) {
          let params = {
            contactName: this.form3.username,
            contactPhone: this.form3.tel,
            codeSms: this.form3.yzm,
          };
          let res = await changeContract(params);
          if (res.code == 0) {
            this.flag2=false;
            this.showChangePeople = false;
            this.identityObj.contactName = this.form3.username;
            this.form3={};
            this.getUserNow();
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    async getUserCode() {
      // 获取手机验证码
      if (this.form3.tel) {
        let param = {
          phone: this.form3.tel + "",
          type: "6",
        };
        let res = await getSmsCodeNew(param);
        let { code, data, msg } = res;
        if (code == 0) {
          this.$message.success(
            `已向手机号为：${this.form3.tel} 成功发送验证码！`
          );
          this.current2 = this.max;
          this.decreseInterval = setInterval(() => {
            if (this.current2 <= this.max && this.current2 > 0) {
              this.current2--;
              this.flag2 = true;
            } else if (this.current2 <= 0) {
              this.current2 = 0;
              this.flag2 = false;
              clearInterval(this.decreseInterval);
            }
          }, 1000);
        } else {
          this.$message.error(msg);
        }
      } else {
        this.$message.error("请先输入联系电话");
      }
    },
    async getTelCode() {
      // 获取手机验证码
      if (this.form2.tel) {
        let param = {
          phone: this.form2.tel + "",
          type: "7",
        };
        let res = await getSmsCodeNew(param);
        let { code, data, msg } = res;
        if (code == 0) {
          this.$message.success(
            `已向手机号为：${this.form2.tel} 成功发送验证码！`
          );
          this.current = this.max;
          this.decreseInterval = setInterval(() => {
            if (this.current <= this.max && this.current > 0) {
              this.current--;
              this.flag = true;
            } else if (this.current <= 0) {
              this.current = 0;
              this.flag = false;
              clearInterval(this.decreseInterval);
            }
          }, 1000);
        } else {
          this.$message.error(msg);
        }
      } else {
        this.$message.error("请先输入新手机号码");
      }
    },
    async getUserNow() {
      let res = await getUserNow();
      if (res.code == 0) {
        this.$store.dispatch("SetIdentityObj", res.data.identityList[0]);
      }
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let params = {
            identityTypeCode: this.identityObj.identityTypeCode,
            areaCode: this.zlform.areaCode?this.zlform.areaCode:this.identityObj.areaCode,
            bankCardId: this.identityObj.bankCardId,
          };
          let res = await changeUserInfo(params);
          let { code, data, msg } = res;
          if (code == 0) {
            this.$message.success(msg);
            this.getUserNow();
            // this.$message.success("修改成功，请重新登录！");
            // this.$store.dispatch("LogOut").then(() => {
            //   // location.reload();
            //   this.$router.push({ name: "login" });
            // });
          } else {
            this.$message.error(msg);
          }
        }
      });
    },
  },
};
</script>

      <style lang="less" scoped>
/deep/.el-form-item__content {
  text-align: left !important;
}
h3 {
  text-indent: 30px;
  margin-bottom: 60px;
}
.reset-pwd {
  text-align: left;
  // margin-top: 46px;
  .title {
    font-size: 15px;
    font-weight: 600;
  }
  .info {
    width: 600px;
    margin-left: 100px;
    text-align: center;
  }
}
</style>
